<template>
  <div>
    <div class="back-icon">
      <img src="../../assets/images/goback.png" @click="$router.go(-1)" />
      实施计划 {{ $route.query.time }}
    </div>
    <div class="select-box">
      <span>
        <span>所属公司：</span>
        <el-select
          v-model="Company1"
          clearable
          placeholder="请选择"
          @change="togetArea"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </span>
      <span>
        <span>所属部门：</span>
        <el-select
          v-model="deptmentName"
          clearable
          @change="togetPost"
          placeholder="请选择"
        >
          <el-option
            v-for="item in option2"
            :key="item.value"
            :label="item.deptmentName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </span>
      <span>
        <span v-if="$route.query.isProcess == 1">所属过程：</span>
        <span v-else>所属岗位：</span>
        <el-select v-model="Area" clearable placeholder="请选择">
          <el-option
            v-for="item in option3"
            :key="item.value"
            :label="item.postName?item.postName:item.processName"
            :value="item.id?item.id:item.processId"
          >
          </el-option>
        </el-select>
      </span>
      <span class="_Select">
        <span style="width: 70px">状态：</span>
        <el-select v-model="ischeck" clearable placeholder="请选择状态">
          <el-option
            v-for="item in [
              { label: '已完成', value: 2 },
              { label: '未完成', value: 4 },
              { label: '未抽题', value: 0 },
              { label: '发现问题点', value: 5},
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </span>
      <el-button
        @click="toSearch"
        icon="el-icon-search"
        class="find"
      ></el-button>
    </div>
    <div class="select-box">
      <el-button type="primary" class="edit" @click="toChoose"
        >新增计划<i class="el-icon-circle-plus-outline el-icon--right"></i
      ></el-button>
      <el-button type="primary" v-show="isShow" class="edit" @click="toSearch2"
        >股份公司内审</el-button
      >
    </div>

    <div class="_TableBox">
      <el-table
        ref="multipleTable"
        :data="tableData.slice(start, end)"
        tooltip-effect="dark"
        style="width: 100%"
        :height="elementHeight"
        highlight-current-row
        @row-dblclick="change"
        @selection-change="handleSelectionChange"
        @select="selectChange"
        @select-all="selectAll"
        @row-click="handleRow"
        :cell-style="cellStyle"
        :stripe="true"
      >
        <!-- <el-table-column type="selection" width="50"> </el-table-column> -->
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column
          :label="$route.query.isProcess != 1 ? '部门/岗位' : '部门/过程'"
          prop=""
          width="150"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.deptName + '/' + scope.row.postName
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="companyName" label="所属公司" width="230">
        </el-table-column>

        <el-table-column
          label="抽取问题数"
          prop="titleCount"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="failCount"
          label="问题点数"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-show="scope.row.status === 0">未抽题</span>
            <span v-show="scope.row.status === 1">已抽题</span>
            <span v-show="scope.row.status === 2">完成</span>
            <span v-show="scope.row.status === 3">整改完成</span>
            <span v-show="scope.row.status === 4">未完成</span>
          </template>
        </el-table-column>
        <el-table-column prop="checkDate" label="检查时间" width="100">
          <template slot-scope="scope">
            <span>{{
              scope.row.checkDate ? scope.row.checkDate.split("T")[0] : ""
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="saveTime" label="创建时间" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.saveTime.split("T")[0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="chargeUserName" label="检查员" width="200">
        </el-table-column>
        <el-table-column prop="saveUserName" label="创建人"> </el-table-column>
        <el-table-column prop="imageCount" label="图片">
          <template slot-scope="scope">
            <span style="color: #889ce5" @click="getOpenImage(scope.row.id)">{{
              scope.row.imageCount
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <img
              style="cursor: pointer; margin: 0 10px"
              @click="delItem(scope.row)"
              src="../../assets/images/Examine_img/del.png"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage1"
        :page-sizes="[10, 20, 50]"
        :page-size="curSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        class="_Pagination"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="新增计划"
      class="dialog"
      :visible.sync="dialogVisible"
      width="25%"
    >
      <div class="text">
        <span>所属公司</span>
        <el-select v-model="Company1" placeholder="请选择" @change="togetArea">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="text">
        <span>所属部门</span>
        <el-select
          v-model="deptmentName1"
          placeholder="请选择"
          @change="togetPost"
        >
          <el-option
            v-for="item in option2"
            :key="item.value"
            :label="item.deptmentName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="text" v-if="$route.query.isProcess != 1">
        <span>所属岗位</span>
        <el-select v-model="Area1" placeholder="请选择">
          <el-option
            v-for="item in option3"
            :key="item.value"
            :label="item.postName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="text" v-if="$route.query.isProcess == 1">
        <span>选择过程</span>
        <el-select v-model="Area1" clearable placeholder="请选择">
          <el-option
            v-for="item in processList"
            :key="item.value"
            :label="item.processName"
            :value="item.processId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="text">
        <span>检查员</span>
        <el-select v-model="users" multiple filterable placeholder="请输入">
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.userName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="text">
        <span>检查日期</span>
        <el-date-picker
          v-model="checkDate"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择检查日期"
          @change="changcheckDate"
        >
        </el-date-picker>
      </div>
      <!-- <div class="text">
        <span>开始时间</span>
        <el-date-picker
          v-model="startTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择开始时间"
        >
        </el-date-picker>
      </div> -->
      <div class="text">
        <span>开始时间</span>
        <el-time-picker
          v-model="startTime"
          :picker-options="{
            selectableRange: '',
          }"
          value-format="HH:mm:ss"
          placeholder="任意时间点"
        >
        </el-time-picker>
      </div>
      <!-- <div class="text">
        <span>结束时间</span>
        <el-date-picker
          v-model="endTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择结束日期"
        >
        </el-date-picker>
      </div> -->
      <!-- <div class="text">
        <span>标准：</span>
        <el-select v-model="standards" multiple placeholder="请选择">
          <el-option
            v-for="item in standardList"
            :key="item.value"
            :label="item.standardAbbr"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div> -->
      <!-- <div class="pic text">
        <div style="margin-bottom: 10px">
          上传图片<span style="margin-top: 5px; color: silver"
            >（点击上传会议图片）</span
          >
        </div>
        <el-upload
          action="https://jsonplaceholder.typicode.com/posts/"
          list-type="picture-card"
          ref="upload"
          :http-request="uploadFile"
          :headers="token"
          :on-remove="handleRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </div> -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAddInsertAreaAudit">确定</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="查看图片"
      class="dialog"
      :visible.sync="showImage"
      width="40%"
    >
      <div style="padding-bottom: 20px">
        <div v-if="srcList.length == 0 && !showUpload" class="none">
          空空如也,<span style="color: blue" @click="showUpload = true"
            >去上传</span
          >
        </div>
        <div class="image-add">
          <div v-if="srcList.length != 0">
            <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
              <el-image
                style="width: 100px; height: 100px; margin-right: 3px"
                :src="item"
                :preview-src-list="srcList"
                @mouseover="overimg(index)"
              >
              </el-image>
              <i
                class="el-icon-delete-solid"
                v-show="index === Curindex"
                @click="delImg(item)"
              ></i>
            </span>
          </div>
          <el-upload
            v-if="showUpload || srcList.length != 0"
            action="https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            ref="upload"
            :http-request="uploadFile"
            :headers="token"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div
          v-if="showUpload || srcList.length != 0"
          @click="reqImage(picId)"
          class="upload-bottom"
        >
          确定
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post, baseURL } from "../../api/http";
import { compressImage } from "../../modules/CompressImage";
import imgTypeChange from "../../modules/imgChange";

export default {
  data() {
    return {
      planId: 0,
      elementHeight: 0,
      BUrl: baseURL[process.env.NODE_ENV + ""],
      token: {
        Authorization:
          "Bearer " + JSON.parse(window.sessionStorage.getItem("token")),
      },
      dialogVisible: false,
      options: [],
      option2: [],
      tableData: [],
      tableData2: [],
      Company: "",
      Area: "",
      extractCount: 0,
      Company1: "",
      Area1: "",
      checkDate: "",
      startTime: "",
      endTime: "",
      isshow: false,
      number: "",
      curArea: "",
      titlelist: "",
      CurId: "",
      deptmentName: "",
      deptmentName1: "",
      option3: [],
      curPost: "",
      ischeck: "",
      start: 0,
      end: 10,
      answer: "",
      isShow: null,
      currentPage1: 1,
      curSize: 10,
      Ctdata: "",
      searchIns: null,
      dialogVisibleC: false,
      planBeginDate: "",
      userList: [],
      users: [],
      reqImageList: [],
      srcList: [],
      showImage: false,
      Curindex: "",
      showUpload: false,
      picId: 0,
      standards: [],
      standardList: [],
      processList: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != "FileList") {
      to.meta.ifDoFresh = true;
    }
    next();
  },
  activated() {
    if (this.$route.meta.ifDoFresh) {
      this.$route.meta.ifDoFresh = false;
      this.getElementHeight();
      this.planId = this.$route.query.planId;
      this.getCompany();
      // this.getdata();
      this.showBtn();
      this.getStandard();

      this.Company1 = Number(this.$route.query.compId);
      this.deptmentName = '';
      this.Area = '';
      this.ischeck = '';
      get("/api/Department/GetNoTree?CompId=" + this.Company1).then((res) => {
        this.option2 = res.data;
      });
      this.getUserList();
    }
    this.getdata();
  },
  // created() {
  //   this.Company1 = Number(this.$route.query.compId);
  //   get("/api/Department/GetNoTree?CompId=" + this.Company1).then((res) => {
  //     this.option2 = res.data;
  //   });
  //   this.getUserList();
  // },
  
  methods: {
    getCompany() {
      // this.Company1 = "";
      this.options = this.$store.state.companyUserList;
    },
    getUserList() {
      get("/api/User/GetByCompId?CompId=" + this.Company1).then((resp) => {
        this.userList = resp.data;
      });
    },
    showBtn() {
      get("/api/NewFileAudit/ShowButton").then((res) => {
        this.isShow = res.data;
      });
    },
    async getdata() {
      await get("/api/NewFileAudit/GetList?PlanId=" + this.planId).then(
        (res) => {
          if (res.code == 200) {
            this.tableData = res.data;
            this.tableData2 = res.data;
          }
        }
      );
    },
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 70 + 70 + 70); //70是div底部距离底部的高度
      });
    },
    getStandard() {
      get("/api/StandardInfo/GetList").then((resp) => {
        if (resp.code == 200) {
          this.standardList = resp.data;
        }
      });
    },
    changcheckDate(e) {
      var planBeginDate = this.$route.query.planBeginDate;
      var planEndDate = this.$route.query.planEndDate;
      var date = new Date(e).toLocaleDateString();
      if (
        Date.parse(date) < Date.parse(planBeginDate) ||
        Date.parse(date) > Date.parse(planEndDate)
      ) {
        this.$message({
          type: "warning",
          message: "检查日期在" + planBeginDate + "至" + planEndDate + "范围内",
        });
        this.checkDate = "";
      }
    },
    async toAddInsertAreaAudit() {
      if (!this.deptmentName1  || this.users.length === 0 || !this.checkDate) {
        let message = "";
        if (!this.deptmentName1) {
          message = "请选择部门";
        }  else if (this.users.length === 0) {
          message = "请选择检查员";
        } else if (!this.checkDate) {
          message = "请选择完成日期";
        }
        this.$message({
          type: "warning",
          message: message
        });
        return;
      }

      this.curCPY = "";
      this.curDept = "";
      this.curPostName = "";
      this.options.forEach((e) => {
        if (e.id === this.Company1) {
          this.curCPY = e.companyName;
        }
      });
      this.option2.forEach((e) => {
        if (e.id === this.deptmentName1) {
          this.curDept = e.deptmentName;
        }
      });
      if (this.$route.query.isProcess != 1) {
        this.option3.forEach((e) => {
          if (e.id === this.Area1) {
            this.curPostName = e.postName;
          }
        });
      } else {
        this.processList.forEach((e) => {
          if (e.processId === this.Area1) {
            this.curPostName = e.processName;
          }
        });
      }
      var userNames = [];
      this.users.map((i) => {
        this.userList.map((e) => {
          if (i == e.id) {
            userNames.push(e.userName);
          }
        });
      });
      var standard = [];
      this.standardList.map((e) => {
        this.standards.map((i) => {
          if (e.id == i) {
            standard.push(e.standardAbbr);
          }
        });
      });
      var fileAudit = {
        id: 0,
        companyId: this.Company1 + "",
        companyName: this.curCPY,
        deptId:
          this.$route.query.isProcess != 1
            ? this.deptmentName1 + ""
            : this.deptmentName1 * 1,
        deptName: this.curDept,
        postId: this.Area1 + "",
        postName: this.curPostName,
        ruleRemark: "",
        remark: "",
        auditType: 0,
        chargeUserId: this.users.toString(),
        chargeUserName: userNames.toString(),
        planId: this.planId,
        status: 0,
        startTime: this.startTime,
        // endTime: this.endTime ? new Date(this.endTime).toISOString() : "",
        saveTime: new Date().toISOString(),
        // checkDate: new Date(this.checkDate).toISOString(),
        checkDate: this.checkDate,
        saveUserId: this.$store.state.userInfo.id + "",
        saveUserNo: this.$store.state.userInfo.userNo,
        saveUserName: this.$store.state.userInfo.userName,
        standardId: this.standards.toString(),
        standard: standard.toString(),
        tenantId: 0,
      };
      var data = {};
      if (this.$route.query.isProcess != 1) {
        data.jsonData = {
          data: JSON.stringify([]),
        };
        data.fileAudit = fileAudit;
      }
      var url =
        this.$route.query.isProcess == 1
          ? "/api/FileProcessAudit/InsertFileAudit"
          : "/api/NewFileAudit/InsertFileAudit";
      await post(
        url +
          "?CompId=" +
          this.Company1 +
          "&DeptId=" +
          this.deptmentName1 +
          (this.$route.query.isProcess == 1 ? "&ProcessId=" : "&PostId=") +
          this.Area1,
        this.$route.query.isProcess == 1 ? fileAudit : data
      ).then((res) => {
        if (res.code == "200") {
          this.dialogVisible = false;
          this.getdata();
          this.$message({
            type: "success",
            message: "新增成功",
          });
          if (this.reqImageList.length != 0) this.reqImage(res.value);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    cellStyle() {
      return "cursor:pointer;";
    },
    delItem(row) {
      this.$confirm(
        "您确定永久删除关于“" + row.companyName + "”的检查文件？",
        "是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteMethod("/api/FileAudit?Id=" + row.id).then((res) => {
            if(res.code == 200){
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.start = 0;
              this.end = 10;
              this.currentPage1 = 1;
              this.curSize = 10;
            }
            if(this.searchIns){
              this.toSearch2();
            }else{
              this.getdata();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    toChoose() {
      this.reqImageList = [];
      this.deptmentName1 = "";
      this.Area1 = "";
      this.checkDate = "";
      this.startTime = "";
      this.users = [];
      this.standards = [];
      this.dialogVisible = true;
    },
    chooseCt() {
      this.dialogVisibleC = true;
      get("/api/AuditType/GetTree").then((res) => {
        this.Ctdata = res.data;
      });
    },
    togetPost(e) {
      this.Area1 = "";
      this.Area = "";
      if (!e) return;
      this.deptmentName1 = e;
      if (this.$route.query.isProcess == 1) {
        this.getProcess();
      } else {
        get("/api/Post?CompId=" + this.Company1 + "&DeptId=" + e).then(
          (res) => {
            this.option3 = res.data;
          }
        );
      }
    },
    getProcess() {
      get(
        "/api/FileProcessAudit/GetProcess?CompId=" +
          this.Company1 +
          "&DeptId=" +
          this.deptmentName1
      ).then((res) => {
        this.processList = res.data;
        this.option3 = res.data;
      });
      // get('/api/User/GetByCompByDept?CompId=' + this.Company1 + "&DeptId=" + this.deptmentName1).then(res =>{
      //   this.userList = res.data;
      // })
    },
    togetArea(e) {
      this.deptmentName = "";
      this.deptmentName1 = "";
      this.Area1 = "";
      this.Area = "";
      this.userList = [];
      if (!e) return;
      this.Company1 = e;
      get("/api/Department/GetNoTree?CompId=" + e).then((res) => {
        this.option2 = res.data;
      });
      this.getUserList();
    },
    handleRow(e) {
      this.CurId = e.id;
      this.$refs.multipleTable.toggleRowSelection(e);
      this.selectChange(this.handleSelectionList);
    },
    toSearch() {
      this.searchIns = false;
      if(this.ischeck== 5){
        get(
        "/api/NewFileAudit/GetByFileAudit?CompId=" +
          this.Company1 +
          "&DeptId=" +
          this.deptmentName1 +
          "&PostId=" +
          this.Area +
          "&Status=" +
          '' +
          "&PlanId=" +
          this.planId
      ).then((res) => {
        this.tableData = res.data;
        this.tableData = this.tableData.filter(item => item.failCount > 0);
      });
      }else{
        get(
          "/api/NewFileAudit/GetByFileAudit?CompId=" +
            this.Company1 +
            "&DeptId=" +
            this.deptmentName1 +
            "&PostId=" +
            this.Area +
            "&Status=" +
            this.ischeck +
            "&PlanId=" +
            this.planId
        ).then((res) => {
          this.tableData = res.data;
        });
      }
    },
    toSearch2() {
      this.searchIns = true;
      get(
        "/api/NewFileAudit/GetByNewFileAudit?CompId=" +
          this.Company1 +
          "&DeptId=" +
          this.deptmentName1 +
          "&PostId=" +
          this.Area +
          "&Status=" +
          this.ischeck +
          "&PlanId=" +
          this.planId
      ).then((res) => {
        this.tableData = res.data;
      });
    },
    handleSelectionChange(value) {
      this.handleSelectionList = value;
    },
    selectChange(selection) {
      if (selection.length > 1) {
        const del_row = selection.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
      }
    },
    handleRowClick(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
      this.selectChange(this.handleSelectionList);
    },
    selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1;
      }
    },
    handleSizeChange(e) {
      this.curSize = e;
      this.start = (this.currentPage1 - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    // changBox() {
    // if (this.searchIns) {
    //   return;
    // }
    // if (this.ischeck == undefined || this.searchIns) {
    //   this.ischeck = 0;
    //   return;
    // }
    // this.tableData = [];
    // this.tableData2.map((item) => {
    //   if (this.ischeck == 2 && (item.status == 2 || item.status == 3)) {
    //     this.tableData.push(item);
    //   } else if (
    //     this.ischeck == 1 &&
    //     (item.status == 0 || item.status == 1)
    //   ) {
    //     this.tableData.push(item);
    //   } else if (this.ischeck == 4 && item.failCount > 0) {
    //     this.tableData.push(item);
    //   }
    // });
    // },
    change(e) {
      sessionStorage.setItem("Fitem", JSON.stringify(e));
      this.$router.push(
        "/file-list?Id=" +
          e.id +
          "&CpyID=" +
          e.companyId +
          "&deptID=" +
          e.deptId +
          "&postId=" +
          e.postId +
          "&planId=" +
          this.planId +
          "&year=" +
          e.checkDate.split("-")[0] +
          "&companyName=" +
          e.companyName +
          "&status=" +
          e.status
      );
    },

    async uploadFile(e) {
      if (e.file) {
        let file = await imgTypeChange(e.file);
        let newfile = await compressImage(file);
        if (newfile.size > 10000) {
          newfile = await compressImage(newfile);
        }
        this.uploadFileReq(newfile, e.file.uid);
      }
    },
    handleRemove(file) {
      this.reqImageList.map((i, index) => {
        if (file.raw.uid == i.uid) {
          this.reqImageList.splice(index, 1);
        }
      });
    },
    uploadFileReq(file, uid) {
      var formData = new FormData();
      formData.append("files", file);
      post("/api/UpLoadFiles/UploadImg", formData)
        .then((res) => {
          if (res.code == 200) {
            this.reqImageList.push({ uid: uid, value: res.value });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.$message.error("上传失败");
        });
    },
    // 新增检查后调用
    reqImage(fileAuditId) {
      if (this.reqImageList.length == 0) {
        this.showUpload = false;
        return;
      }
      var data = [];
      this.reqImageList.map((e) => {
        data.push({
          FileAuditId: fileAuditId,
          ImageRemark: "",
          ImageAddress: this.BUrl + "/uploads/" + e.value,
          SaveTime: new Date().toISOString(),
          SaveUserId: this.$store.state.userInfo.id,
        });
      });
      post("/api/FileAuditImage", {
        data: JSON.stringify(data),
      }).then(() => {
        this.tableData.map((i) => {
          if (fileAuditId * 1 == i.id) {
            i.imageCount += this.reqImageList.length;
          }
        });
        this.getImage(fileAuditId);
        this.$refs.upload.clearFiles();
      });
    },
    getOpenImage(e) {
      this.showImage = true;
      this.getImage(e);
    },
    getImage(e) {
      this.picId = e;
      this.showUpload = false;
      this.srcList = [];
      this.reqImageList = [];
      get("/api/FileAuditImage/" + e).then((res) => {
        this.imgList = res.value;
        res.value.forEach((element) => {
          this.srcList.push(element.picNameAndAddr);
        });
      });
    },
    overimg(index) {
      this.Curindex = index;
    },
    delImg(item) {
      this.imgList.forEach((e) => {
        if (e.picNameAndAddr === item) {
          this.$confirm("此操作将永久删除该图片", "是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              deleteMethod("/api/FileAuditImage?Id=" + e.id).then(() => {
                this.getImage(this.CurId);
                this.tableData.map((i) => {
                  if (this.CurId == i.id) {
                    i.imageCount -= 1;
                  }
                });
              });
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-table__body tr.current-row > td {
  background: #d4e5f9 !important;
}
.select-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  div {
    margin-right: 10px;
  }
}
.back-icon {
  margin-bottom: 10px;
  img {
    width: 15px;
  }
}
.dialog {
  .text {
    margin-bottom: 10px;
    > span {
      width: 70px;
      display: inline-block;
      text-align: start;
    }
  }
  /deep/ .el-input {
    width: 200px !important;
  }
  /deep/ .el-upload--picture-card {
    width: 100px !important;
    height: 100px !important;
    line-height: 100px !important;
  }
  /deep/ .el-dialog__body {
    padding: 30px 20px !important;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 100px;
    height: 100px;
  }
  .image-add {
    display: flex;
  }
  /deep/ .el-select__tags {
    flex-wrap: nowrap !important;
    overflow: hidden;
  }
}
.none {
  text-align: center;
}
.upload-bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: blue;
  left: 0;
  text-align: center;
  color: white;
  padding: 10px 0;
}
</style>
<style scoped>
@import "../../assets/style/Examine_CSS.css";
</style>
